var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.porcentajeIcon,"dense":"","rules":[
                function (v) { return /^[0-9]?[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                  /^-[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                  'Formato no válido'; }
              ],"label":"Porcentaje sin DDJJ","outlined":""},model:{value:(_vm.porcSinDdjj),callback:function ($$v) {_vm.porcSinDdjj=$$v},expression:"porcSinDdjj"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.porcentajeIcon,"rules":[
                function (v) { return /^[0-9]?[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                  /^-[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                  'Formato no válido'; }
              ],"dense":"","label":"Porcentaje con DDJJ","outlined":""},model:{value:(_vm.porcConDdjj),callback:function ($$v) {_vm.porcConDdjj=$$v},expression:"porcConDdjj"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período desde","hint":"Formato AAAAMM","persistent-hint":"","type":"text","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(_vm.rules.periodoYyyyMm)},model:{value:(_vm.vigenciaDesde),callback:function ($$v) {_vm.vigenciaDesde=$$v},expression:"vigenciaDesde"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período hasta","type":"text","hint":"Formato AAAAMM","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","persistent-hint":"","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.vigenciaDesde, _vm.vigenciaHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.vigenciaHasta),callback:function ($$v) {_vm.vigenciaHasta=$$v},expression:"vigenciaHasta"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }