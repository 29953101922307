<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />

        <v-data-table
          :headers="headers"
          :items="ingresosTopeEnCuota"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          :sort-by="['vigenciaHasta']"
          :sort-desc="true"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" :md="canConfigConceptos ? '6' : '8'">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" align="end" v-if="canConfigConceptos">
                  <v-btn
                    color="primary"
                    outlined
                    @click="openModalConceptos()"
                    class="to-right"
                  >
                    Configurar conceptos
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  :md="canConfigConceptos ? '2' : '4'"
                  align="end"
                  v-if="canCreate"
                >
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar ingreso</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteTopeCuotas(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar ingreso</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="35%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditConfigDDJJIngresoParaTopesEnCuotas
        :editTopeEnIngreso="editTopeEnIngreso"
        @closeAndReload="closeAndReload"
        :ddjjtopesCuotaConfId="ddjjtopesCuotaConfId"
      ></EditConfigDDJJIngresoParaTopesEnCuotas>
    </v-dialog>
    <v-dialog
      v-if="openModalConfigConceptos"
      v-model="openModalConfigConceptos"
      max-width="35%"
      @keydown.esc="openModalConfigConceptos = false"
      persistent
    >
      <ConfigConceptosDDJJTopesCuotas
        @closeConfigConceptos="openModalConfigConceptos = false"
      ></ConfigConceptosDDJJTopesCuotas>
    </v-dialog>
    <DeleteDialog
      :isLoading="isLoading"
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConfigDDJJIngresoParaTopesEnCuotas from "@/components/modules/cuotas/devengamientos/EditConfigDDJJIngresoParaTopesEnCuotas.vue";
import ConfigConceptosDDJJTopesCuotas from "@/components/modules/cuotas/devengamientos/ConfigConceptosDDJJTopesCuotas.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditConfigDDJJIngresoParaTopesEnCuotas,
    GoBackBtn,
    ConfigConceptosDDJJTopesCuotas,
    Ayuda
  },
  name: "ConfigTopesEnRemuneraciones",
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    isFormValid: false,
    search: "",
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.CONFIG_DDJJ_INGRESOS_TOPES_CUOTAS,
    showFilters: true,
    filtersApplied: [],
    showDeleteModal: false,
    idToDelete: null,
    periodoDesde: null,
    editTopeEnIngreso: {},
    ddjjtopesCuotaConfId: null,
    ingresosTopeEnCuota: [],
    routeToGo: "DDJJIngresosParaTopesEnCuotas",
    title: enums.titles.CONFIG_DDJJ_INGRESOS_PARA_TOPES_EN_CUOTAS,
    titleDelete: enums.titles.DELETE_DDJJ_TOPES_EN_CUOTAS,
    rules: rules,
    headers: [
      {
        text: "Tope de cuota sin DDJJ(%)",
        value: "porcSinDdjj",
        sortable: false,
        align: "start"
      },

      {
        text: "Tope de cuota con DDJJ(%)",
        value: "porcConDdjj",
        sortable: false
      },
      {
        text: "Período desde",
        value: "vigenciaDesde",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "vigenciaHasta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canConfigConceptos: false,
    openModalConfigConceptos: false,
    openModalEdit: false,
    openModalLog: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadIngresos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },

  methods: {
    ...mapActions({
      configGetDDJJTopeCuotas: "devengamientos/configGetDDJJTopeCuotas",
      deleteConfigDdjjTopesCuotas: "devengamientos/deleteConfigDdjjTopesCuotas",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_INGRESO_TOPES_CUOTAS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_INGRESO_TOPES_CUOTAS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_INGRESO_TOPES_CUOTAS:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_CONCEPTOS_DDJJ_INGRESOS_TOPES_CUOTAS:
            this.canConfigConceptos = true;
            break;
          default:
            break;
        }
      });
    },
    async loadIngresos() {
      this.isLoading = true;
      const data = await this.configGetDDJJTopeCuotas();
      this.ingresosTopeEnCuota = data;
      this.isLoading = false;
    },

    deleteTopeCuotas(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.ddjjtopesCuotaConfId;
    },
    async confirmDelete() {
      this.isLoading = true;
      const response = await this.deleteConfigDdjjTopesCuotas({
        ddjjtopesCuotaConfId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadIngresos();
        this.isLoading = true;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editTopeEnIngreso = item;
    },
    openModalConceptos() {
      this.openModalConfigConceptos = true;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadIngresos();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
