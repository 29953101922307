<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="porcSinDdjj"
                :append-icon="porcentajeIcon"
                dense
                :rules="[
                  v =>
                    /^[0-9]?[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                    /^-[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                    'Formato no válido'
                ]"
                label="Porcentaje sin DDJJ"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="porcConDdjj"
                :append-icon="porcentajeIcon"
                :rules="[
                  v =>
                    /^[0-9]?[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                    /^-[0-9]?[0-9](\.[0-9][0-9]?)?$/.test(v) ||
                    'Formato no válido'
                ]"
                dense
                label="Porcentaje con DDJJ"
                outlined
              >
              </v-text-field>
            </v-col>
            <!-- Periodo Desde -->
            <v-col cols="6" sm="6" class="pb-0">
              <v-text-field
                dense
                outlined
                v-model="vigenciaDesde"
                label="Período desde"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="rules.required.concat(rules.periodoYyyyMm)"
              ></v-text-field>
            </v-col>
            <!-- Periodo Hasta -->
            <v-col cols="6" sm="6" class="pb-0">
              <v-text-field
                dense
                outlined
                v-model="vigenciaHasta"
                label="Período hasta"
                type="text"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(vigenciaDesde, vigenciaHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { mask } from "vue-the-mask";

export default {
  name: "EditConfigDDJJIngresoParaTopesEnCuotas",
  directives: { mask },
  components: { CurrencyInput },
  props: {
    editTopeEnIngreso: { type: Object }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_CONFIGURACION_INGRESOS_TOPE,
    newTitle: enums.titles.NUEVA_CONFIGURACION_INGRESOS_TOPE,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    rules: rules,
    porcConDdjj: null,
    porcSinDdjj: null,
    vigenciaDesde: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    vigenciaHasta: null
  }),
  created() {
    if (this.editTopeEnIngreso) {
      this.setTopeIngreso();
    } else {
      this.newTopeIngreso();
    }
  },
  methods: {
    ...mapActions({
      configGetDDJJTopeCuotas: "devengamientos/configGetDDJJTopeCuotas",
      saveConfigDdjjTopesCuotas: "devengamientos/saveConfigDdjjTopesCuotas",
      setAlert: "user/setAlert"
    }),

    async setTopeIngreso() {
      this.ddjjtopesCuotaConfId = this.editTopeEnIngreso.ddjjtopesCuotaConfId;
      this.porcSinDdjj = this.editTopeEnIngreso.porcSinDdjj;
      this.porcConDdjj = this.editTopeEnIngreso.porcConDdjj;
      this.vigenciaDesde = this.editTopeEnIngreso.vigenciaDesde;
      this.vigenciaHasta = this.editTopeEnIngreso.vigenciaHasta;
    },
    async newTopeIngreso() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
      const data = {
        ddjjtopesCuotasConfId: this.ddjjtopesCuotaConfId,
        porcConDdjj: this.porcConDdjj,
        porcSinDdjj: this.porcSinDdjj,
        vigenciaDesde: this.vigenciaDesde,
        vigenciaHasta: this.vigenciaHasta
      };
      const res = await this.saveConfigDdjjTopesCuotas(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
