<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text pt-0">{{ title }}</v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="conceptos-form"
          form="conceptos-form"
          id="conceptos-form"
          @submit.prevent="saveConfigConceptos()"
        >
          <!-- Autocomplete conceptos -->
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-autocomplete
                v-model="conceptosAjusteConIvaSelected"
                :items="conceptos"
                label="Con ajuste de IVA"
                :loading="loading"
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-autocomplete
                v-model="conceptosAjusteSinIvaSelected"
                :items="conceptos"
                label="Sin ajuste de IVA"
                :loading="loading"
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="10" class="py-0">
              <v-text-field
                v-model="cantidadMesesPrevioDdjjTope"
                label="Meses previos a consultar"
                outlined
                dense
                :rules="[
                  rules.minMaxNumber(cantidadMesesPrevioDdjjTope, 0, 36)
                ]"
                v-mask="'##'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pb-0 pt-2">
              <div>
                <v-tooltip left max-width="10rem">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="1.4rem"
                      class="primary--text"
                      v-on="on"
                      v-bind="attrs"
                    >
                      {{ infoIcon }}</v-icon
                    >
                  </template>
                  <span
                    >Cantidad de meses retroactivos a consultar para el tope de
                    cuotas</span
                  >
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cancelar </v-btn>
        <v-btn
          :loading="loadingSave"
          type="submit"
          :disabled="!isFormValid || loadingSave"
          form="conceptos-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "ConfigConceptosDDJJTopesCuotas",
  directives: { mask },
  data() {
    return {
      title: "Conceptos ajuste de IVA",
      isFormValid: false,
      loading: false,
      loadingSave: false,
      conceptos: [],
      conceptosAjusteConIvaSelected: null,
      conceptosAjusteSinIvaSelected: null,
      cantidadMesesPrevioDdjjTope: null,
      rules: rules,
      headers: [{ text: "Concepto", value: "value", sortable: true }],
      infoIcon: enums.icons.SNB_INFO
    };
  },
  created() {
    this.setSelects();
  },
  methods: {
    ...mapActions({
      saveConfigConceptosDdjjTopesCuotas:
        "devengamientos/saveConfigConceptosDdjjTopesCuotas",
      getConfigConceptosDDJJTopeCuotas:
        "devengamientos/getConfigConceptosDDJJTopeCuotas",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      this.loading = true;
      const configConceptos = await this.getConfigConceptosDDJJTopeCuotas();
      if (configConceptos.ajusteConIva > 0) {
        this.conceptosAjusteConIvaSelected = configConceptos.ajusteConIva;
      }
      if (configConceptos.ajusteSinIva > 0) {
        this.conceptosAjusteSinIvaSelected = configConceptos.ajusteSinIva;
      }
      this.conceptos = configConceptos.conceptosCombo;

      this.cantidadMesesPrevioDdjjTope =
        configConceptos.cantidadMesesPrevioDdjjTope;
      this.loading = false;
    },
    closeModal() {
      this.$emit("closeConfigConceptos");
    },
    async saveConfigConceptos() {
      this.loadingSave = true;
      if (
        this.conceptosAjusteConIvaSelected == this.conceptosAjusteSinIvaSelected
      ) {
        this.setAlert({
          type: "warning",
          message: "No puede seleccionar el mismo concepto para ambas opciones."
        });
      } else {
        const data = {
          AjusteConIva: this.conceptosAjusteConIvaSelected,
          AjusteSinIva: this.conceptosAjusteSinIvaSelected,
          CantidadMesesPrevioDdjjTope: this.cantidadMesesPrevioDdjjTope
        };
        const res = await this.saveConfigConceptosDdjjTopesCuotas(data);
        if (res.status == 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        }
      }
      this.loadingSave = false;
    }
  }
};
</script>

<style></style>
